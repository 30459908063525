<template>
	<div :style="background" class="call-number">
		<div class="header">
			<img class="logo" src="../assets/image/government_icon.jpg" />
			<div class="title">
				<p class="zh">河北省石家庄市长安区政务服务中心</p>
				<p class="en">SHIJIAZHUANG CHANGAN DISTRICT CITZEN SERVICE CENTER</p>
			</div>
		</div>
		<div class="line-white"></div>
		<div class="body">
			<div class="left">
				<div class="body-top">
					<div class="top-title">
						<p>欢迎您</p>
						<p style="margin-left:10px">{{userInfo.realName}}</p>
					</div>
					<div class="top-txt" style="padding-top:5px">您的工号：{{userInfo.username}}</div>
					<div class="top-txt" style="padding-top:15px">您今天已办理 {{solved}} 项业务</div>
					<div class="top-txt" style="padding-top:5px">
						当前等待办理业务
						<span class="now-number">{{waiting}}</span> 人.
					</div>
					<div class="top-txt now-number" style="padding-top:15px">当前：{{nowNum}}</div>
				</div>
				<div class="body-bottom">
					<div class="bottom-cont">
						<div class="item" @click="nextCall()">
							<img
								class="item-img"
								style="width:41px;height:25px"
								src="../assets/image/call_next.png"
							/>
							<span class="item-text">跳过此号</span>
						</div>
						<div class="item" @click="suspendCall()">
							<img
								class="item-img"
								style="width:41px;height:41px"
								src="../assets/image/call_suspend.png"
							/>
							<span class="item-text">暂停</span>
						</div>
						<div class="item" @click="openPingjia()">
							<img
								class="item-img"
								style="width:39px;height:34px"
								src="../assets/image/call_evaluate.png"
							/>
							<span class="item-text">评价</span>
						</div>
						<div class="item" @click="welcomeCall()">
							<img
								class="item-img"
								style="width:41px;height:41px"
								src="../assets/image/call_welcome.png"
							/>
							<span class="item-text">欢迎光临</span>
						</div>
						<div class="item" @click="resetCall()">
							<img
								class="item-img"
								style="width:42px;height:37px"
								src="../assets/image/call_reset.png"
							/>
							<span class="item-text">重新呼叫</span>
						</div>
						<div class="item" @click="openCall()">
							<img
								class="item-img"
								style="width:32px;height:32px"
								src="../assets/image/call_number.png"
							/>
							<span class="item-text">呼叫</span>
						</div>
					</div>
				</div>
				<div class="exit">
					<div @click="quitLogin">退出登录</div>
					<img src="../assets/image/about.png" class="about" @click="openAbout" />
				</div>
			</div>
			<div class="center">
				<div class="center-cont">
					<div class="center-title">
						<div class="line-blue"></div>
						<p class="center-title-txt">业务汇总</p>
					</div>
					<el-form
						:model="queryParams"
						style="padding-bottom:20px"
						ref="queryForm"
						:inline="true"
						label-width="89px"
					>
						<el-form-item label="手机号" prop="phonenumber">
							<el-input
								v-model="queryParams.phonenumber"
								placeholder="请输入手机号"
								clearable
								size="small"
								@keyup.enter.native="handleQuery"
							/>
						</el-form-item>
						<el-form-item label="业务状态" prop="status">
							<el-select
								v-model="queryParams.status"
								placeholder="请选择业务状态"
								clearable
								size="small"
							>
								<el-option
									v-for="dict in subscribeStatusList"
									:key="dict.dictValue"
									:label="dict.dictLabel"
									:value="dict.dictValue"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="取号时间" prop="dateRange">
							<el-date-picker
								v-model="dateRange"
								size="small"
								style="width: 240px"
								value-format="yyyy-MM-dd"
								type="daterange"
								range-separator="-"
								start-placeholder="取号开始时间"
								end-placeholder="取号结束日期"
							></el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button
								type="primary"
								icon="el-icon-search"
								size="mini"
								@click="handleQuery"
							>搜索</el-button>
							<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
						</el-form-item>
					</el-form>
					<el-table
						v-loading="loading"
						:data="subscribeList"
						border
						@selection-change="handleSelectionChange"
					>
						<!-- <el-table-column type="selection" width="55" align="center" /> -->
						<el-table-column label="业务状态" align="center" prop="statusCn" />
						<el-table-column label="取号时间" align="center" prop="takeTime">
							<template slot-scope="scope">
								<span>{{ parseTime(scope.row.takeTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
							</template>
						</el-table-column>
						<el-table-column label="用户信息" align="center" prop="nickName">
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" placement="left-end">
									<div slot="content" v-html="getTip(scope.row)"></div>
									<!-- <div>{{scope.row.phonenumber}}</div> -->
									<el-tag>{{scope.row.phonenumber}}</el-tag>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							label="业务名称"
							align="center"
							prop="businessName"
							show-overflow-tooltip
						/>
						<el-table-column
							label="票号"
							align="center"
							width="80"
							prop="ticketNoFull"
						/>
						<el-table-column label="叫号时间" align="center" prop="callTime">
							<template slot-scope="scope">
								<span>{{ parseTime(scope.row.callTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
							</template>
						</el-table-column>
						<el-table-column label="受理人" align="center" prop="resUserName" />
						<el-table-column label="受理完成时间" align="center" prop="finishTime">
							<template slot-scope="scope">
								<span>{{ parseTime(scope.row.finishTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
							</template>
						</el-table-column>
						<el-table-column
							label="填报状态"
							align="center"
							prop="hcpId"
							width="100"
							fixed="right"
						>
							<template slot-scope="scope">
								<span
									:style="scope.row.hcpId == null?color.red:color.green"
								>{{ scope.row.hcpId == null?'未填报':'已填报' }}</span>
							</template>
						</el-table-column>
						<el-table-column
							label="操作"
							align="center"
							class-name="small-padding fixed-width"
							fixed="right"
						>
							<template slot-scope="scope">
								<el-button
									size="mini"
									type="text"
									icon="el-icon-edit"
									@click="handleUpdate(scope.row,'listButton')"
								>{{ scope.row.hcpId == null?'填报':'查看' }}</el-button>
							</template>
						</el-table-column>
					</el-table>

					<pagination
						v-show="total>0"
						:total="total"
						:page.sync="queryParams.pageNum"
						:limit.sync="queryParams.pageSize"
						@pagination="getList"
					/>
					<!-- 添加或修改预约业务对话框 -->
					<el-dialog
						:title="title"
						:visible.sync="open"
						width="60%"
						:show-close="configValue"
						append-to-body
						:close-on-click-modal="false"
					>
						<el-form
							ref="hcpForm"
							:model="hcpForm"
							:rules="hcpRules"
							style="padding-left:30px;padding-top:40px"
							label-width="160px"
							label-position="left"
						>
							<el-form-item label="取号时间:" prop="takeTime">{{form.takeTime}}</el-form-item>
							<el-form-item label="用户姓名" prop="nickName">{{form.nickName}}</el-form-item>
							<el-form-item label="身份证号" prop="idCard">{{form.idCard}}</el-form-item>
							<el-form-item label="联系电话" prop="phonenumber">{{form.phonenumber}}</el-form-item>
							<el-form-item label="业务名称" prop="businessName">{{form.businessName}}</el-form-item>
							<el-form-item label="受理人" prop="resUserName">{{form.resUserName}}</el-form-item>
							<el-form-item
								class="dia-item"
								style="margin-top:10px"
								label="事项名称"
								prop="taskId"
							>
								<treeselect
									@select="loadUserProp"
									v-model="hcpForm.taskId"
									:options="typeOptions"
									:disable-branch-nodes="true"
									:normalizer="normalizerItem"
									placeholder="选择事项"
								/>
							</el-form-item>
							<el-form-item class="dia-item" label="申请人类型" prop="userProp">
								<el-select
									style="width:100%"
									v-model="hcpForm.userProp"
									@change="loadNickName"
									placeholder="请选择"
								>
									<el-option
										v-for="item in userPropOptions"
										:key="item.id"
										:label="item.name"
										:value="String(item.id)"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item
								class="dia-item"
								label="企业"
								prop="userName"
								v-show="hcpForm.userProp!=null && hcpForm.userProp!=1 && this.form.hcpId == null"
							>
								<div class="dia-item-choice">
									<el-input v-model="hcpForm.userName" disabled clearable size="small" />
									<div class="dia-chice-btn" @click="openCom=true">请选择</div>
								</div>
							</el-form-item>
							<el-form-item class="dia-item" label="申请人名称" prop="userName">
								<el-input v-model="hcpForm.userName" clearable size="small" />
							</el-form-item>
							<el-form-item class="dia-item" label="申请人证件类型" prop="userPageType">
								<el-select
									style="width:100%"
									v-model="hcpForm.userPageType"
									disabled
									size="small"
								>
									<el-option label="统一信用代码" value="001" />
									<el-option label="身份证" value="111" />
								</el-select>
							</el-form-item>
							<el-form-item class="dia-item" label="申请人证件号码" prop="certKeyGOV">
								<el-input
									v-model="hcpForm.certKeyGOV"
									placeholder="请输入申请人证件号码"
									clearable
									size="small"
								/>
							</el-form-item>
							<el-form-item
								class="dia-item"
								label="法人代表"
								prop="legalUserName"
								v-show="hcpForm.userProp!=null && hcpForm.userProp!=1"
							>
								<el-input
									v-model="hcpForm.legalUserName"
									placeholder="请输入法人代表"
									clearable
									size="small"
								/>
							</el-form-item>
							<el-form-item label="通讯地址" prop="address">
								<el-input
									v-model="hcpForm.address"
									placeholder="请输入申请人证件号码"
									clearable
									size="small"
								/>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button
								type="primary"
								@click="submitForm"
								v-show="this.form.hcpId == null"
							>确 定</el-button>
							<el-button v-if="configValue" @click="cancel">取 消</el-button>
						</div>
					</el-dialog>
					<el-dialog
						title="选择企业"
						:visible.sync="openCom"
						width="80%"
						append-to-body
						:close-on-click-modal="false"
					>
						<div class="dia-item-choice" style="padding-bottom:30px;padding-top:30px">
							<div style="width:100px">企业名称</div>
							<el-input v-model="companyTxt" clearable size="small" />
							<div class="dia-chice-btn" @click="searchCompany()">查询</div>
						</div>
						<el-table v-loading="loadingCom" :data="unitList" border>
							<el-table-column label="企业名称" align="center" prop="unitname" />
							<el-table-column label="统一代码" align="center" prop="unitcode" />
							<el-table-column label="法人代表" align="center" prop="legalPerson" />
							<el-table-column label="地址" align="center" width="400" prop="unitaddr" />
							<el-table-column
								label="操作"
								width="80"
								align="center"
								class-name="small-padding fixed-width"
								fixed="right"
							>
								<template slot-scope="scope">
									<span
										@click="choiceCompany(scope.row)"
										style="color:#5EB95E;font-size:12px;cursor:pointer"
									>选择</span>
								</template>
							</el-table-column>
						</el-table>
					</el-dialog>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from "../api/index";
import { getUserInfo, removeToken } from '../utils/auth'
import { Toast } from 'vant'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
	components: { Treeselect },
	data () {
		return {
			background: {
				backgroundColor: '#5A9AD1',
				// 背景图片地址
				backgroundImage: 'url(' + require('../assets/image/lineup_comm_bg_land.png') + ')',
				// 背景图片是否重复
				backgroundRepeat: 'no-repeat',
				// 背景图片大小
				backgroundSize: '100% 100%',
				// 背景图片位置
				backgroundPosition: '0 0'
			},
			resetScoket: true,
			socket: null,
			userInfo: {},
			callDeviceMacAddr: '',
			status: 1,
			solved: 0,//已办理的数量
			waiting: 0,//等待办理的数量
			list: [],
			waitList: [],//待办列表
			callRecord: {},
			id: '',//评价的时候传的id
			timer: null,
			nowNum: '',//当前叫号
			voiceTimer: null,//点击评价之后调获取list接口后的数组
			audio: null,
			code: 108,
			isClear: false,
			dialogVisible: false,

			// 查询参数
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				orgDeptId: null,
				hcpUpload: null,
				orgDeptName: null,
				deptId: null,
				resUserId: null,
				resUserName: null,
				userId: null,
				status: null,
				statusCn: null,
				orderTime: null,
				ticketNoFull: null,
				businessId: null,
				businessName: null,
				businessCode: null,
				doing: null,
				ticketNo: null,
				nickName: null,
				idCard: null,
				phonenumber: null,
				takeTime: null,
				callTime: null,
				finishTime: null,
				winNo: null,
				evalLevel: null,
				score: null,
				evalTime: null,
				callMac: null,
				hasPJQ: null,
				poorDeal: null,
				orderByColumn: 'takeTime',
				isAsc: 'descending'
			},
			// 遮罩层
			loading: true,
			// 选中数组
			ids: [],
			// 非单个禁用
			single: true,
			// 非多个禁用
			multiple: true,
			// 日期范围
			dateRange: [],
			// 总条数
			total: 0,
			subscribeList: [],
			subscribeStatusList: [],
			color: {
				red: 'color: red',
				green: 'color: green',
			},
			open: false,
			openCom: false,
			title: '',
			// 表单参数
			form: {},
			hcpForm: {},
			typeOptions: [],
			userPropOptions: [],
			unitList: [],
			hcpRules: {
				taskId: [
					{ required: true, message: "事项名称不能为空", trigger: "blur" }
				],
				userProp: [
					{ required: true, message: "申请人类型不能为空", trigger: "blur" }
				],
				userName: [
					{ required: true, message: "申请人名称不能为空", trigger: "blur" }
				],
				userPageType: [
					{ required: true, message: "申请人证件类型不能为空", trigger: "blur" }
				],
				certKeyGOV: [
					{ required: true, message: "申请人证件号码不能为空", trigger: "blur" }
				],
				legalUserName: [
					{ required: true, message: "法人代表不能为空", trigger: "blur" }
				],
				address: [
					{ required: true, message: "通讯地址不能为空", trigger: "blur" }
				],
			},
			companyTxt: '',
			loadingCom: false,
			configValue: false,
			firstPlay: true
		}
	},
	mounted () {
		let macAddr = localStorage.getItem("macAddr");
		this.initWebSocket(macAddr)
		this.callDeviceMacAddr = macAddr

		let info = getUserInfo()
		this.userInfo = JSON.parse(info)

		this.getInBusinessRecord()

		this.dateRange = []
		this.setDefaultDate()
		this.$set(this.queryParams, 'callMac', macAddr)
		this.reset()
		// this.getList()
		this.subscribeStatus()
		// this.getDeptTreeselect()
		//  this.voicePlay();
		this.firstPlayAudio()
	},
	destroyed () {
		this.resetScoket = false;
		this.socket?.close()
		clearInterval(this.timer)
		this.timer = null
		clearInterval(this.voiceTimer)
		this.voiceTimer = null
	},
	watch: {
		waitList: {
			handler (newVal, oldVal) { // newVal 和 oldVal长度可能相等

			},
			deep: true,
		},
		dialogVisible (newVal) {
			if (!newVal) {
				this.voicePause()
			}
		}
	},
	methods: {
		/** 查询部门下拉树结构 */
		getDeptTreeselect: async function () {
			const res = await api.treeselect()
			if (res.code == 200) {
				this.deptOptions = res.data;
			}
		},
		/** 搜索按钮操作 */
		handleQuery () {
			this.queryParams.pageNum = 1;
			this.getList();
		},
		/** 重置按钮操作 */
		resetQuery () {
			this.dateRange = []
			this.setDefaultDate()
			this.queryParams.businessId = null;
			this.resetForm("queryForm");
			this.handleQuery();
		},
		/** 查询预约业务列表 */
		getList: async function () {
			this.loading = true;
			const res = await api.listSubscribe(this.tansParams(this.addDateRange(this.queryParams, this.dateRange)))
			this.loading = false;
			if (res.code == 200) {
				this.subscribeList = res.rows;
				this.total = res.total;
			} else {
				this.$toast({
					message: res.msg,
					position: 'middle'

				});
			}
		},
		addDateRange (params, dateRange, propName) {
			let search = params;
			search.params = typeof (search.params) === 'object' && search.params !== null && !Array.isArray(search.params) ? search.params : {};
			dateRange = Array.isArray(dateRange) ? dateRange : [];
			if (typeof (propName) === 'undefined') {
				search.params['beginTime'] = dateRange[0];
				search.params['endTime'] = dateRange[1];
			} else {
				search.params['begin' + propName] = dateRange[0];
				search.params['end' + propName] = dateRange[1];
			}
			return search;
		},
		tansParams (params) {
			let result = ''
			for (const propName of Object.keys(params)) {
				const value = params[propName];
				var part = encodeURIComponent(propName) + "=";
				if (value !== null && typeof (value) !== "undefined") {
					if (typeof value === 'object') {
						for (const key of Object.keys(value)) {
							if (value[key] !== null && typeof (value[key]) !== 'undefined') {
								let params = propName + '[' + key + ']';
								var subPart = encodeURIComponent(params) + "=";
								result += subPart + encodeURIComponent(value[key]) + "&";
							}
						}
					} else {
						result += part + encodeURIComponent(value) + "&";
					}
				}
			}
			return result
		},
		setDefaultDate () {
			this.dateRange[0] = this.getDate(0);
			this.dateRange[1] = this.getDate(0);
		},
		getDate (offset) {
			var nowDate = new Date();
			if (offset != null && offset != 0) {
				nowDate.setDate(nowDate.getDate() + offset)
			}
			var year = nowDate.getFullYear();
			var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
				: nowDate.getMonth() + 1;
			var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
				.getDate();
			var dateStr = year + "-" + month + "-" + day;
			return dateStr;
		},
		subscribeStatus: async function () {
			const res = await api.subscribeStatus(this.callDeviceMacAddr)
			if (res.code == 200) {
				this.subscribeStatusList = res.data
			}
		},
		// 多选框选中数据
		handleSelectionChange (selection) {
			this.ids = selection.map(item => item.id)
			this.single = selection.length !== 1
			this.multiple = !selection.length
		},
		getTip (row) {
			let info = "";
			info += row.nickName == null ? "" : "姓名：" + row.nickName + "<br/>";
			info += row.phonenumber == null ? "" : "手机号：" + row.phonenumber + "<br/>";
			info += row.idCard == null ? "" : "身份证号：" + row.idCard;
			return info;
		},
		normalizerItem (node) {
			if (node.children && !node.children.length) {
				delete node.children;
			}
			return {
				id: node.id,
				label: node.typeName,
				children: node.children
			};
		},
		loadNickName (node) {
			if (node === '1') {
				this.$set(this.hcpForm, 'userName', this.form.nickName)
				this.$set(this.hcpForm, 'userPageType', '111')
				this.$set(this.hcpForm, 'certKeyGOV', this.form.idCard)
				this.$set(this.hcpForm, 'address', '石家庄长安区')
				this.$set(this.hcpForm, 'legalUserName', '无')
			} else {
				this.$set(this.hcpForm, 'userName', '')
				this.$set(this.hcpForm, 'userPageType', '001')
				this.$set(this.hcpForm, 'certKeyGOV', '')
				this.$set(this.hcpForm, 'address', '')
			}
		},
		loadUserProp (node) {
			this.$set(this.form, 'userProp', null)
			this.userPropOptions = [];
			if (node.userProp != null) {

				let item = node.userProp.split(',')
				console.log('-----', item)
				if (item.includes('1')) {
					this.userPropOptions.push({ id: '1', name: '自然人' })
				}
				if (item.includes('2')) {
					this.userPropOptions.push({ id: '2', name: '企业法人' })
				}
				if (item.includes('3')) {
					this.userPropOptions.push({ id: '3', name: '事业法人' })
				}
				if (item.includes('4')) {
					this.userPropOptions.push({ id: '4', name: '社会组织法人' })
				}
				if (item.includes('5')) {
					this.userPropOptions.push({ id: '5', name: '非法人企业' })
				}
				if (item.includes('6')) {
					this.userPropOptions.push({ id: '6', name: '行政机关' })
				}
				if (item.includes('9')) {
					this.userPropOptions.push({ id: '9', name: '其他组织' })
				}
			}
		},
		setUnitToForm (unit) {
			console.log(unit)
			this.$set(this.hcpForm, 'unitObj', unit)
			this.$set(this.hcpForm, 'userName', unit.unitname)
			this.$set(this.hcpForm, 'certKeyGOV', unit.unitcode)
			this.$set(this.hcpForm, 'address', unit.unitaddr)
			this.$set(this.hcpForm, 'legalUserName', unit.legalperson)
		},
		searchCompany () {
			this.getUnitList()
		},
		choiceCompany (row) {
			this.openCom = false
			this.setUnitToForm(row)
		},
		getUnitList: async function () {
			let param = {
				unitname: this.companyTxt,
				pageNum: 1,
				pageSize: 10
			}
			this.loadingCom = true
			const res = await api.getUnitList(param)
			this.loadingCom = false
			if (res.code == 200) {
				this.unitList = res.rows
			}
		},
		/** 修改按钮操作 */
		handleUpdate: async function (row, source) {

			// this.reset();
			this.resetHcp();
			const id = row.id || this.ids
			const res = await api.getSubscribe(id)
			if (res.code == 200) {
				this.form = res.data;
				if (source == 'listButton') {
					this.configValue = true
				} else {
					this.getUploadOrder()
				}
				if (this.configValue) {
					this.open = true;
					this.title = "填报政务事项";
					let params = {
						businessId: row.businessId
					}
					const response = await api.listHcpdb(params)
					if (response.code == 200) {
						this.typeOptions = this.handleTree(response.data);
						if (this.form.hcpId != null) {
							const reshcp = await api.getHcp(this.form.hcpId)
							if (reshcp.code == 200) {
								this.userPropOptions.push({ id: reshcp.data.userProp, name: reshcp.data.userPropName })
								this.hcpForm = reshcp.data
							}
						}
					}
				} else {
					this.open = false;
				}
			}
		},
		resetHcp () {
			this.hcpForm = {
				hcpId: null,
				subscribeId: null,
				taskId: null,
				userProp: null,
				userName: null,
				userPageType: null,
				certKeyGOV: null,
				legalUserName: null,
				address: null,
			}
			this.resetForm("hcpForm");
		},
		/** 提交按钮 */
		submitForm () {
			this.$refs["hcpForm"].validate(valid => {
				if (valid) {
					if (this.hcpForm.hcpId == null) {
						this.$set(this.hcpForm, 'subscribeId', this.form.id)
						this.addHcp()
					} else {
						this.$toast({
							message: '不可重复填报',
							position: 'middle'
						});
					}
				}
			});
		},
		addHcp: async function () {
			const res = await api.addHcp(this.hcpForm)
			if (res.code == 200) {
				this.$toast({
					message: '填写成功',
					position: 'middle'
				});
				this.open = false;
				this.getList();
			}
		},
		// 取消按钮
		cancel () {
			this.open = false;
			// this.reset();
			this.resetHcp();
		},
		closeDial () {
			this.voicePause()
			this.dialogVisible = false
		},
		suportNotification () {
			if (window.Notification) {
				if (window.Notification.permission) {
					this.showNotificationMessage();
				}
			}
		},
		async showNotificationMessage () {
			await new Promise((reslove) => { setTimeout(() => { reslove() }, 1000) })
			window.Notification.requestPermission(function (status) {
				console.log("status", status)
				if (status === "granted") {
					new window.Notification("您有一条新消息请及时处理", {
						body: "",
						icon: "../assets/logo.png"
					})
				}
			})
		},
		firstPlayAudio () {
			let url = "https://tts.baidu.com/text2audio?cuid=baike&spd=5&lan=ZH&ctp=1&pdt=301&vol=4&rate=32&per=0&tex=' " + encodeURI("您有一条待办业务，请及时处理");
			this.audio = new Audio(url);
			this.audio.src = url;
			this.audio.m
			this.audio.play();//播放阅读
			if (this.firstPlay) {
				setTimeout(() => {
					this.audio.pause()
					this.audio.load()
				}, 10)
				this.firstPlay = false;
			}
		},
		//语音播报
		voicePlay () {
			//通知
			this.suportNotification()
			//弹窗
			this.dialogVisible = true
			//声音
			if (this.voiceTimer) {
				clearInterval(this.voiceTimer);
				this.voiceTimer = null;
			}
			this.voiceTimer = setTimeout(() => {
				let url = "https://tts.baidu.com/text2audio?cuid=baike&spd=5&lan=ZH&ctp=1&pdt=301&vol=4&rate=32&per=0&tex=' " + encodeURI("您有一条待办业务，请及时处理");
				this.audio = new Audio(url);
				this.audio.src = url;
				this.audio.m
				this.audio.play();//播放阅读
				if (this.firstPlay) {
					setTimeout(() => {
						this.audio.pause()
						this.audio.load()
						this.audio.play();//播放阅读
					}, 1000)
					this.firstPlay = false;
				}

			}, 8000)
		},
		voicePause () {
			if (this.audio) {
				if (this.voiceTimer) {
					clearInterval(this.voiceTimer);
					this.voiceTimer = null;
				}
				if (!this.audio.paused) {
					this.audio.pause();
				}
			}
		},
		//跳转到关于页面
		openAbout () {
			this.$router.push({
				name: 'AboutInfo',
			})
		},
		//点击呼叫
		openCall () {
			this.voicePause()
			this.callTicket()
		},
		//点击评价
		openPingjia () {
			this.voicePause()
			this.onEval()
		},
		//跳过此号
		nextCall () {
			this.voicePause()
			this.skipTicket()
		},
		//暂停服务
		suspendCall () {
			this.voicePause()
			this.pauseService()
		},
		//重新呼叫
		resetCall: async function () {

			this.voicePause()
			const res = await api.repeatCall(this.callDeviceMacAddr)
			if (res.code == 200) {
				this.$toast({
					message: '点击成功',
					position: 'middle'
				});
			} else {
				this.$toast({
					message: res.msg,
					position: 'middle'

				});
			}
		},
		//欢迎光临
		welcomeCall: async function () {
			this.voicePause()
			const res = await api.welcome(this.callDeviceMacAddr)
			Toast(res.msg)
		},
		//正在办理的业务
		getInBusinessRecord: async function () {
			const res = await api.getInBusinessRecord(this.callDeviceMacAddr);
			if (res.code == 200) {
				let num = res.data?.ticketNoFull ?? ''
				let army = res.data?.army ?? false
				let txt = ""
				if (army) {
					txt = "（军人）"
				} else {
					txt = ""
				}
				this.nowNum = num + txt
			}
		},
		//叫号器 - 代办列表
		getCallList: async function () {
			this.waitList = []
			const res = await api.getCallList(this.callDeviceMacAddr);
			if (res.code == 200) {
				this.waitList = res?.data ?? []
				this.waiting = this.waitList.length
			}
		},
		//叫号器 - 已办列表
		getCallListFinish: async function () {
			this.list = []
			const res = await api.getCallListFinish(this.callDeviceMacAddr);
			if (res.code == 200) {
				this.list = res?.data ?? []
				this.solved = this.list.length
			}
		},
		//叫号器 - 叫号
		callTicket: async function () {
			const res = await api.callTicket(this.callDeviceMacAddr);
			if (res.code == 200) {
				// this.nowNum = res.data?.ticketNoFull ?? ''
				this.reset()
				this.getListCall()
			} else {
				Toast(res.msg)
			}
			this.getInBusinessRecord()
		},
		/** 查询预约业务列表 */
		getListCall: async function () {
			this.loading = true;
			const res = await api.listSubscribe(this.tansParams(this.addDateRange(this.queryParams, this.dateRange)))
			this.loading = false;
			if (res.code == 200) {
				this.subscribeList = res.rows;
				this.total = res.total;
				if (Array.isArray(this.subscribeList) && this.subscribeList.length > 0) {
					this.handleUpdate(this.subscribeList[0], 'callButton')
				}
			} else {
				this.$toast({
					message: res.msg,
					position: 'middle'

				});
			}
		},
		getUploadOrder: async function () {

			const res = await api.getUploadOrder()
			if (res.code == 200) {
				let dataList = res.rows
				if (Array.isArray(dataList) && dataList.length > 0) {
					this.configValue = dataList[0].configValue == 'true' ? true : false
				}
			}
		},
		//叫号器 - 评价
		onEval: async function () {
			const res = await api.onEval(this.callDeviceMacAddr);
			if (res.code == 200) {
				Toast('请提醒用户评价')
				this.reset()
				this.getInBusinessRecord()
			} else {
				Toast(res.msg)
			}

		},
		//跳过此号
		skipTicket: async function () {
			const res = await api.skipTicket(this.callDeviceMacAddr);
			if (res.code == 200) {
				this.reset()
				this.getInBusinessRecord()
			} else {
				Toast(res.msg)
			}
		},
		//暂停服务
		pauseService: async function () {
			this.nowNum = ''
			const res = await api.pauseService(this.callDeviceMacAddr);
			if (res.code == 200) {
				Toast('点击成功')
				this.getInBusinessRecord()
			} else {
				Toast(res.msg)
			}
		},
		//叫号器 - 退出登录
		logout: async function () {
			const res = await api.logout(this.callDeviceMacAddr);
			if (res.code === 200) {
				this.$router.go(-1)
				//退出登录成功后
				removeToken()
			}
		},
		quitLogin () {
			this.logout()
		},
		reset () {
			this.getCallList()
			// this.getCallListFinish()
			this.getList()

		},
		initWebSocket (macAddr) {
			this.socket = new WebSocket("wss://caq.etuic.com/eval-api/ws/queue/" + macAddr)
			this.socket.onopen = this.socketOnOpen
			this.socket.onmessage = this.socketOnMessage;
			this.socket.onclose = this.socketOnClose;
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.timer = setInterval(() => {
				this.socket?.send(macAddr);
			}, 180000)
		},
		socketOnOpen () {
			// console.log("连接建立成功");
		},
		socketOnMessage (res) {
			console.log("后台传递数据", res);

			if (/^客户端/.test(res.data) || /^设备/.test(res.data)) {
				return
			}
			let json = JSON.parse(res?.data ?? '{}')
			let code = json.code;
			this.code = code
			console.log(this.waitList.length === 0)
			console.log(this.status == 1)
			console.log(this.code !== 108)
			console.log(this.code)
			this.isClear = this.waitList.length === 0 && (this.code === 108 || this.code === 102 || this.code === 104)
			console.log(this.isClear)
			if (this.waitList.length === 0 && this.isClear && this.status == 1 && this.code !== 108) {
				this.voicePlay();
			}

			//客户取号 code:102 呼叫新号 code:103 跳过次号 code:104  业务已完成 code:108
			if (code === 102 || code === 103 || code === 104 || code === 108) {
				if (code === 104) {
					this.code = 108
				}
				this.reset()
				this.getInBusinessRecord()

			} else if (code === 101 || code === 110) { //平台设置设备 code:101 叫号机退出登录 code:110
				removeToken()
				this.$router.replace({
					name: 'DeviceConnect',
					query: {
						refresh: true
					}
				})
			} else if (code === 112) {//给led屏发送长链接
				let ip = json.ip
				let msg = json.msg
			}
		},
		socketOnClose () {
			if (this.resetScoket) {
				this.initWebSocket(this.callDeviceMacAddr);
			}
		},
		socketSendMessgae (mes) {
			let message = JSON.stringify(mes);
			this.socket?.send(message);
		},
	}
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
	padding: 15px 20px;
}
::v-deep .el-dialog__header {
	padding: 10px 20px 0;
}
::v-deep .el-dialog__title {
	padding-left: 30px;
}
.el-form-item {
	margin-bottom: 0 !important;
}
::v-deep .el-form--label-left .el-form-item__label {
	font-size: 16px !important;
	color: #172b4e !important;
}
::v-deep .el-form-item__content {
	font-size: 16px !important;
	color: #6c788d;
}
.dia-item {
	padding-bottom: 20px;
}
.dia-item-choice {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.dia-chice-btn {
	background-color: #0052cc;
	border-radius: 4px;
	width: 90px;
	height: 30px;
	line-height: 30px;
	margin-left: 8px;
	color: #fff;
	text-align: center;
	font-size: 14px;
}
.call-number {
	height: 100%;
	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 37px;
		padding-top: 10px;
		box-sizing: border-box;
		.logo {
			width: 60px;
			height: 60px;
		}
		.title {
			padding-left: 0.187rem;
			.zh {
				color: #fff;
				font-size: 20px;
			}
			.en {
				color: #fff;
				padding-top: 5px;
				font-size: 10px;
			}
		}
	}
	.line-white {
		position: relative;
		width: 100%;
		height: 20px;
	}
	.line-white::after {
		content: "";
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 1px;
		background-color: #fff;
	}
	.body {
		display: flex;
		height: calc(100% - 90px);
		width: 100%;
		flex-direction: row;
		.left {
			flex: 0 0 380px;
			color: #fff;
			box-sizing: border-box;
			font-size: 12px;
			position: relative;
			.body-top {
				display: flex;
				flex-direction: column;
				padding-top: 30px;
				padding-left: 60px;
				box-sizing: border-box;
				.top-title {
					display: flex;
					flex-direction: row;
					font-size: 18px;
				}
				.top-txt {
					font-size: 18px;
				}
			}
			.body-bottom {
				display: flex;
				width: 100%;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding-top: 32px;
				align-items: center;
				box-sizing: border-box;
				flex-wrap: wrap;
				.bottom-cont {
					display: flex;
					flex-direction: row;
					margin-left: 16px;
					justify-content: center;
					align-items: center;
					align-items: center;
					box-sizing: border-box;
					flex-wrap: wrap;
				}
				.item {
					width: 134px;
					height: 116px;
					background-color: #fff;
					border-radius: 18px;
					margin-top: 14px;
					margin-right: 16px;
					color: #0193f0;
					box-sizing: border-box;
					font-size: 14px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.item-img {
						margin-bottom: 20px;
					}
				}
			}
			.now-number {
				color: red;
			}
			.exit {
				position: absolute;
				display: flex;
				flex-direction: row;
				bottom: 10px;
				left: 20px;
				.about {
					display: block;
					margin-left: 10px;
					height: 20px;
					width: 20px;
				}
			}
		}
		.center {
			flex: 1;
			overflow: hidden;
			padding: 23px 30px 16px 0px;
			box-sizing: border-box;
			overflow: hidden;
			width: 100%;
			height: 100%;
			.center-cont {
				background-color: #fff;
				border-radius: 4px;
				box-sizing: border-box;
				padding-left: 20px;
				padding-right: 20px;
				overflow: auto;
				width: 100%;
				height: 100%;
				.center-title {
					display: flex;
					flex-direction: row;
					padding: 20px 20px 24px 24px;
					box-sizing: border-box;
					align-content: center;
					.line-blue {
						background-color: #2d85d0;
						width: 3px;
						height: 25px;
					}
					.center-title-txt {
						padding-left: 10px;
						font-size: 16px;
						color: #000000;
					}
				}
			}
		}
	}
}
.about-input {
	color: #000;
	margin-top: 10px;
}
.army-red {
	color: #f44336;
}
</style>
<style >
.van-toast--text {
	width: auto !important;
}
</style>
